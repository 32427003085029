'use client';

import type { BoxProps } from '../Box';
import { Box } from '../Box';

/**
 * This component is used for layout purposes. It adds a grey box around you children.
 * It is used in different places, e.g. Detailview and Recommendations.
 */
export const SlotBox = ({ sx, ...props }: BoxProps) => (
  <Box sx={{ p: [1.5, 1.5, 3], backgroundColor: 'grey.light', pb: [3, 3, 5], ...sx }} {...props} />
);
