'use client';

import { ArrowLargeLeft32, ArrowLargeRight32 } from '@packages/themes/icons';
import useEmblaCarousel from 'embla-carousel-react';
import { Children, useEffect, useState } from 'react';
import { Box } from '../../Box';
import { Stack } from '../../Stack';
import { CarouselDotNav } from '../DotNav/DotNav';
import { CarouselThumbNav } from '../ThumbNav/ThumbNav';
import {
  carouselViewportStyles,
  getCarouselChildWrapperStyles,
  getCarouselFlexWrapperStyles,
  getCarouselWrapperStyles,
} from '../styles';
import type { CarouselType } from '../types';
import { getEmblaPlugins } from './helper';
import { ArrowButton } from '../ArrowButton/ArrowButton';

/**
 * Carousel Component based on https://www.embla-carousel.com
 */
export const Carousel = ({
  children,
  showDots = false,
  showArrows = true,
  arrowsInsideBoundary = false,
  visibleSlides = 1,
  slidesToScroll = 1,
  gap = 0,
  autoplay = false,
  loop = false,
  emblaOptions = {},
  thumbnailConfig,
  loadingState = false,
  index: indexToSelect = 0,
  onChange = () => {},
  sx,
  aria,
  hasOffset = false,
}: CarouselType) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(indexToSelect);
  const [hovered, setHovered] = useState<boolean>(false);

  const onMouseEnter = () => showArrows === 'hover' && setHovered(true);
  const onMouseLeave = () => showArrows === 'hover' && setHovered(false);

  // Main Carousel Settings
  const [viewportRef, embla] = useEmblaCarousel(
    {
      duration: 32,
      loop,
      align: 'start',
      slidesToScroll,
      containScroll: 'trimSnaps',
      ...emblaOptions,
    },
    getEmblaPlugins(autoplay),
  );

  // init everything
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (embla) {
      const onSelect = () => {
        setSelectedIndex(embla.selectedScrollSnap());
        onChange(embla.selectedScrollSnap());
      };

      onSelect();

      embla.on('select', onSelect);

      return () => {
        embla.off('select', onSelect);
      };
    }
  }, [embla, onChange]);

  useEffect(() => {
    if (embla && embla.selectedScrollSnap() !== indexToSelect) {
      embla.scrollTo(indexToSelect, true);
    }
  }, [indexToSelect, embla]);

  const displayArrows =
    embla !== undefined && (showArrows === true || (showArrows === 'hover' && hovered));

  const scrollSnaps = embla?.scrollSnapList();

  const noThumbnails =
    thumbnailConfig === undefined || (thumbnailConfig && thumbnailConfig.thumbnails.length === 0);

  return (
    <Stack direction="column" gap="0.75rem" flex={1}>
      <Box
        sx={getCarouselWrapperStyles(sx)}
        onMouseEnter={() => onMouseEnter()}
        onMouseLeave={() => onMouseLeave()}
      >
        <Box
          sx={{
            ...(hasOffset && { p: 0.25 }),
            ...carouselViewportStyles,
          }}
          data-index={selectedIndex}
        >
          <div ref={loadingState ? null : viewportRef}>
            <Box sx={getCarouselFlexWrapperStyles(gap)}>
              {Children.map(children, (child, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Box key={index} sx={getCarouselChildWrapperStyles(visibleSlides, gap)}>
                  {child}
                </Box>
              ))}
            </Box>
          </div>
        </Box>
        {displayArrows && (
          <>
            {embla.canScrollPrev() && (
              <ArrowButton
                sx={{
                  left: (theme) => (arrowsInsideBoundary ? theme.spacing(1.5) : '-20px'),
                }}
                onClick={() => embla.scrollPrev()}
                {...(aria?.buttonPrev && {
                  'aria-label': aria.buttonPrev,
                })}
              >
                <ArrowLargeLeft32 />
              </ArrowButton>
            )}
            {embla.canScrollNext() && (
              <ArrowButton
                sx={{
                  right: (theme) => (arrowsInsideBoundary ? theme.spacing(1.5) : '-20px'),
                }}
                onClick={() => embla.scrollNext()}
                {...(aria?.buttonNext && {
                  'aria-label': aria.buttonNext,
                })}
              >
                <ArrowLargeRight32 />
              </ArrowButton>
            )}
          </>
        )}
      </Box>

      {showDots && scrollSnaps && scrollSnaps.length > 1 && (
        <CarouselDotNav
          scrollSnaps={scrollSnaps}
          selectedIndex={selectedIndex}
          scrollTo={(index: number) => embla?.scrollTo(index)}
        />
      )}

      {/* eslint-disable-next-line no-nested-ternary */}
      {noThumbnails ? null : !embla ? (
        // fallback just needed to fix layout shift
        <Box sx={{ height: [60, 60, 96] }} />
      ) : (
        <CarouselThumbNav
          emblaMain={embla}
          thumbnailConfig={thumbnailConfig}
          selectedIndex={selectedIndex}
        />
      )}
    </Stack>
  );
};
