import type { FC } from 'react';
import { WishlistCss32 } from '@packages/themes/icons';
import { keyframes } from '@mui/system';
import { CircularProgress } from '../CircularProgress';
import { Button } from '../Button';
import type { ButtonProps } from '../Button';

export type WishlistButtonProps = {
  /** set active state on wishlist button */
  isActive: boolean;
  /**
   * button loading state
   * @default false
   */
  loading?: boolean;
} & ButtonProps;

const beat = keyframes`
  from {
    transform: scale(1);
  }
  15% {
    transform: scale(0.8);
  }
  50%, 80% {
    transform: scale(1.15)
  }
  to {
    transform: scale(1);
  }
`;

/**
 * WishlistButton component based on https://mui.com/material-ui/react-button/#icon-button
 * */
export const WishlistButton: FC<WishlistButtonProps> = ({
  isActive = false,
  variant = 'text',
  size = 'large',
  loading = false,
  sx,
  children,
  onClick,
  ...rest
}) => (
  <Button
    variant={variant}
    size={size}
    onClick={onClick}
    sx={{
      ...sx,
      width: children ? 'auto' : '2.75rem',
      px: children ? 'auto' : 0,
      py: 0,
      borderColor: 'text.darkTransparent',
      'path:last-child': { fill: (theme) => theme.palette.text.dark },
      '&:hover': {
        bgcolor: variant === 'text' ? 'transparent' : 'auto',
        'path:last-child': { fill: (theme) => theme.palette.text.dark },
      },
      ...(isActive && {
        'path:last-child': { fill: (theme) => theme.palette.primary.main },
      }),
    }}
    {...rest}
    endIcon={
      loading ? (
        <CircularProgress size="s" />
      ) : (
        <WishlistCss32
          sx={{
            width: 32,
            height: 32,
            path: {
              transition: 'fill 200ms ease-out, stroke 200ms ease-out',
              fill: variant === 'text' ? '#ffffff' : 'transparent',
            },
            ...(isActive && {
              '& > path': { fill: (theme) => theme.palette.primary.main },
              animation: `${beat} 500ms`,
            }),
          }}
        />
      )
    }
    aria-label={children ? undefined : rest['aria-label']}
  >
    {children}
  </Button>
);
